
export default {
    name: 'Pepper Videos App - PepperTix',
    head() {
        return {
            title: 'Pepper Tix App - PepperTix',
            meta: [
                { hid: 'description', name: 'description', content: 'Welcome to Pepper Tix App. Your application for events and favorites.' }
            ]
        }
    },
    data() {
        return {


        }
    },
    components: {},
    methods: {
        ClickBody() {
            this.$store.commit("ClickBody", true);

        },
    },
}
